import React from 'react';
import chunk from 'lodash.chunk';
import { Row, Col } from '@components/Grid';
import { IFormField } from './FormField';
import { FormField } from '.';
import { Style } from './Form';

interface IFormSection {
  columnGap?: number;
  customRenderer?: Function;
  values: any;
  styles?: Style[];
  form: string;
  sections: any;
  id: string;
}

/**
 * Renders chunks of items as Rows and Columns. Chunk can be either a field
 * (fullWidth === true), or an array of fields
 */
const FormSection: React.FC<IFormSection> = ({
  columnGap, customRenderer, values, styles, form, sections, id,
}) => {
  /**
   * Renders a (input) field, if field doesn't have a custom renderer specified
   */
  const renderField = (field: IFormField, index?: string) => {
    if (!field.id) {
      console.error('Field is missing id:', field);
      return null;
    }

    let value = null;

    if (values) {
      const getVal = (obj: any, i: string) => {
        if (!obj) return null;
        return obj[i];
      }
      value = field.id.split('.').reduce(getVal, values);
    }

    return (<FormField
      form={form}
      key={field.id}
      id={field.id}
      inputId={`${field.id}-${index}`}
      value={value}
      styles={styles}
      readOnly={!!styles?.includes('readOnly')}
      {...field}
    />
    );
  }

  const getElem = (item: IFormField, index?: string) => {
    if (item.type === 'customRender' && customRenderer) return customRenderer(item, index, values);
    return renderField(item, index);
  };

  if (!sections) return <span>FormSection - No sections provided</span>;
  return (
    <div>
      {sections.map((fieldChunk: IFormField[] | IFormField) => {
        // Now, these thangs belong to columns
        if (Array.isArray(fieldChunk)) {
          const columns = chunk(fieldChunk, Math.ceil(fieldChunk.length / 2));
          return (
            <Row className="row" key={`chunk-${fieldChunk[0].id}`} columnGap={columnGap || 2} columns={2}>
              {columns.map((column, index) => (
                <Col key={`col-${column[0].id}`}>{column.map((field) => getElem(field, `${id}-${index}`))}</Col>
              ))}
            </Row>
          );
        }
        // Naw, these thangs do not
        return getElem(fieldChunk);
      })}
    </div>
  );
};

export { FormSection };
