import React from 'react';
import { Button, Spinner } from '@primitives';
import styled from 'styled-components';
import { rem, transitions } from 'polished';
import { FormattedMessage as M } from 'gatsby-plugin-intl';

interface IFormSubmitButton {
  text?: string;
  className?: string;
  disabled?: boolean;
  type?: string;
  onClick?: Function;
  id: string;
  submitting?: boolean;
  submitText?: string;
  mt?: string;
  translate: boolean;
}

const StyledButton = styled(Button)`
  height: ${rem('45px')};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Button {
  submitting?: boolean;
}
const ButtonText = styled.span`
  opacity: ${(props: Button) => (props.submitting ? '0' : '1')};
  top: ${(props: Button) => (props.submitting ? '50px' : '0.85rem')};
  position: ${(props: Button) => (props.submitting ? 'absolute' : 'static')};
  ${transitions(['opacity', 'top'], '0.25s ease-in 0.25s')};
`;

const StyledSpinner = styled(Spinner)`
  height: 0;
  width: 0;
  opacity: 0;
  top: -50px;
  position: absolute;
  left: calc(50% - 15px);

  &.visible {
    top: 0.6rem;
    height: 30px;
    width: 30px;
    opacity: 1;
  }

  ${transitions(['opacity', 'top'], '0.25s ease-in 0.25s')};
`;

const FormSubmitButton: React.FC<IFormSubmitButton> = ({
  submitting, submitText, children, id, translate, ...rest
}) => {
  const resolvedButtonText = translate ? <M id={submitText || 'fields.label.saveChanges'} /> : submitText;
  return (
    <StyledButton id={id} type="submit" color="white" background="textBlue" {...rest}>
      <StyledSpinner className={submitting ? 'visible' : ''} />
      <ButtonText submitting={submitting}>
        {!children && resolvedButtonText}
      </ButtonText>
      {children}
    </StyledButton>
  );
};

export { FormSubmitButton };
