import React from 'react';
import { ReadOnlyForm } from '@components/Form';
import styled from 'styled-components';
import { Text, Hr } from '@components/Primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
interface AgreementPartiesForm {
  data: IAgreementParty[];
  id: string;
  fields: any;
}

const SecondaryResidentHeader = styled.div`
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
  }
`;

const AgreementPartiesForm: React.FC<AgreementPartiesForm> = ({ data, id, fields }) => {
  if (!data) return null;

  const primary = data.find((p) => p.current_party);
  const secondary = data.find((p) => !p.current_party);

  return (
    <div>
      {primary && (
        <ReadOnlyForm
          values={primary}
          fields={fields.primary}
          id={`${id}-primary-resident`}
        />
      )}
      {secondary && (
        <div>
          <SecondaryResidentHeader>
            <Text mr="3" color="grey" fontWeight="500" uppercase><M id="pages.myInformation.agreementsPage.secondParty" /></Text>
            <Hr />
          </SecondaryResidentHeader>
          <ReadOnlyForm
            values={secondary}
            fields={fields.secondary}
            id={`${id}-secondary-resident`}
          />
        </div>
      )}
    </div>
  );
};

export { AgreementPartiesForm };
