import React from 'react';
import { ReadOnlyForm } from '@components/Form';
import { IAgreement } from '../../../types';

interface AgreementGeneralForm {
  data: IAgreement;
  fields: any;
  id: string;
}

const AgreementGeneralForm: React.FC<AgreementGeneralForm> = ({ data, id, fields }) => {
  const general = { ...{}, ...data };
  general.owner_name = general.owner?.name;
  general.owner_business_id = general.owner?.business_id;
  general.owner_address = general.owner?.address;
  return (
    <ReadOnlyForm
      values={general}
      fields={fields}
      id={id}
    />
  );
};

export { AgreementGeneralForm };

