import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { RadioButton, RadioButtonProps } from './RadioButton';

interface Props {
  id: string;
  name?: string;
  value: any;
  options: RadioButtonProps[];
  onChange: Function;
}

const RadioButtonContainer = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const RadioButtonGroup: React.FC<Props> = ({
  options, name, id, value, onChange,
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);
  const onRadioButtonChange = (e: any) => {
    setSelected(e.target.value);
    onChange(e.target.value);
  };

  if (!options) return <div />;

  return (
    <RadioButtonContainer>
      {options.map(({ id: radioButton, label }: RadioButtonProps) => (
        <RadioButton
          label={label || radioButton}
          checked={selected === radioButton}
          key={radioButton}
          id={`${name}_${radioButton}`}
          name={name || id}
          onChange={onRadioButtonChange}
          value={radioButton}
        />
      ))}
    </RadioButtonContainer>
  );
};
export { RadioButtonGroup };
