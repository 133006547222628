import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import {
  Select, Label, ReadonlyValue, Textarea, Input, Text, Box,
} from '@primitives';
import { FileUploader } from '@components/FileUploader';
import { FormattedMessage as M, FormattedDate as Date } from 'gatsby-plugin-intl';
import { CheckboxGroup, Checkbox } from '@components/Checkbox';
import { RadioButtonGroup } from '@components/RadioButton';
import { rem } from 'polished';
import { required as requiredValidator } from './formValidators';
import { InputType, Style } from './Form';
import theme, { until } from '../../styles';
export interface IFormField {
  type?: InputType;
  required?: boolean;
  validators?: Function[];
  id: string;
  inputId: string; // unique label id
  form: string;
  value: string | number | boolean;
  label?: string;
  styles?: Style[];
  readOnly?: boolean;
  hideLabel?: boolean;
  fullWidth?: boolean;
  inputProps?: any;
  parser?: 'date' | 'boolean' | 'link';
  subFields: {
    fields: IFormField[];
  };
}


const ErrorContainer = styled(Box)`
  text-align: right;
  font-size: 0.8rem;
  position: absolute;
  padding: 0.5rem 1rem;
  top: -3rem;
  border-radius: 2px;
  box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.07);
  &:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: ${theme.colors.errorRed} transparent transparent transparent;
    position: absolute;
    bottom: -10px;
  }
  &.centerLabels {
    margin-top: -1rem;
    margin-bottom: 1rem;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
`;

const RequiredIndicator = styled.span`
  margin-left: 0.25rem;
`;

const InputContainer = styled.div<{ isReadOnly: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  ${(props => props.isReadOnly && 'overflow: hidden')};

  > input {
    width: 100%;
  }
  > span {
    margin-top: 0.75rem;
    margin-bottom: 0rem;
  }

  /* READONLY */
  > p {
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
    height: 1.3rem;
  }

   a {
    color: ${(props) => props.theme.colors.black};
  }
  ${until('lg', `
    > span {
      margin-top: 0rem;
      margin-bottom: 1rem;
    }
  `)};
`;

const InputLabel = styled(Label)`
  display: block;
  width: 100%;
  font-weight:  500;
  margin-bottom: 0;
  margin-bottom: 0;
  max-width: ${rem('150px')};
  margin-right: 1rem;
  &.group {
    margin-bottom: 1.5rem;
  }

  &.centerLabels {
    max-width: none;
    margin-right: 0;
    font-weight: 300;
    margin-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  ${until('lg', `
    margin-bottom: 0.5rem;
    max-width: none;
  `)};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;


  &.group, &.checkbox {
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  &.centerLabels {
    margin-bottom: 1.5rem;
    flex-direction: column;
  }

  &.readOnly {
    margin-bottom: 1rem;
  }


  &.labelOnTop {
    flex-direction: column;
    align-items: flex-start;
    // Mucho importanter
    margin-bottom: 1rem !important;

    label {
      margin-bottom: 0.5rem;
      width: 100%;
      max-width: none;
    }
  }

  ${until('lg', `
    display: block;
  `)}

  ${until('md', `
    &.readOnly {
      margin-bottom: 0.5rem;
    }
  `)}
`;

const composeValidators = (...validators: any) => (value: string) => {
  return validators.reduce((error: string, validator: Function) => error
    || validator(value), undefined);
};

const FormField: React.FC<IFormField> = ({
  form, value, label, validators, type, id, parser, inputId: idPostfix,
  styles, required, hideLabel, inputProps, ...fieldProps
}) => {
  let Component = undefined as any;

  // Form is read only, OR field is read only
  const isReadOnly = styles?.includes('readOnly') || type === 'readOnly';

  const getClassName = (baseClass?: string) => {
    let cls = baseClass || '';
    if (styles) cls = `${cls} ${styles.join(' ')}`;
    if (type === 'checkboxGroup' || type === 'radioButtonGroup') cls = `${cls} group`;
    if (type == 'checkbox') cls = `${cls} checkbox`;
    cls = `${cls} field_${id}`;
    cls = `${cls} ${type}`;
    return cls;
  };

  const getReadOnlyValue = () => {
    if (value === undefined || value === null) return '';
    if (parser === 'link') return <a href={value as string} target="_blank">{value}</a>;
    if (parser === 'date' && typeof value === 'string') return <Date value={value} />;
    if (parser === 'boolean') {
      const trues = ['yes', 'kyllä', 'true'];
      let translationId = 'fields.label.no';
      if (value === undefined) return undefined;
      if (value === true || (typeof value === 'string' && trues.includes(value.toLowerCase()))) translationId = 'fields.label.yes';
      return <M id={translationId} />;
    }
    return value;
  };
  let allValidators = [] as any;

  // Validators
  if (required) allValidators = [...validators || [], ...[requiredValidator]];

  switch (type as IFormField['type']) {
    case 'checkboxGroup':
      Component = CheckboxGroup;
      break;
    case 'radioButtonGroup':
      Component = RadioButtonGroup;
      break;
    case 'checkbox':
      Component = Checkbox;
      break;
    case 'textarea':
      Component = Textarea;
      break;
    case 'select':
      Component = Select;
      break;
    case 'file':
      Component = FileUploader;
      break;
    default:
      Component = Input;
      break;
  }

  const rest = {} as { validate: any };
  if (allValidators.length) rest.validate = composeValidators(...allValidators);
  const inputId = `${form}-control_${idPostfix}`;

  return (
    <Field name={id} type={type === 'checkbox' ? 'checkbox' : undefined} {...rest}>
      {({ input, meta }) => {
        return (
          <FieldWrapper>
            <InputWrapper className={getClassName('input-wrapper')}>
              {!hideLabel && (
                <InputLabel htmlFor={inputId} className={getClassName()}>
                  <M id={`fields.label.${label || id}`} />
                  {required && <RequiredIndicator>*</RequiredIndicator>}
                </InputLabel>
              )}
              <InputContainer isReadOnly={isReadOnly}>
                {!isReadOnly && (
                  <Component
                    type={type}
                    id={inputId}
                    label={(inputProps && inputProps.label) || id || label}
                    {...input}
                    {...fieldProps}
                  />
                )}
                {isReadOnly && <ReadonlyValue id={inputId}>{getReadOnlyValue()}</ReadonlyValue>}
                {meta.error && meta.touched && meta.submitFailed && (
                  <ErrorContainer color="errorRed" className={getClassName()}>
                    {meta.error && meta.touched && <Text color="white"><M id={meta.error} /></Text>}
                  </ErrorContainer>
                )}
              </InputContainer>
            </InputWrapper>

          </FieldWrapper>
        );
      }}
    </Field>
  );
};

export { FormField };
