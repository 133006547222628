import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { Box, Spinner } from '@primitives';
import { transitions } from 'polished';
import { until } from '@styles';
import { diff } from 'deep-object-diff';
import { FormSubmitButton } from '.';
import { IFormField } from './FormField';
import { FormSection } from './FormSection';
import { FormReset } from './FormReset';

export type InputType = 'readOnly' | 'select' | 'text' | 'textarea' | 'checkboxGroup' | 'checkbox' | 'radioButtonGroup' | 'date' | 'heading' | 'default' | 'customRender' | 'repeat' | 'file';
export type Style = 'readOnly' | 'centerLabels' | 'labelOnTop' | 'singleColumn';

interface IForm {
  fields: any;
  onSubmit?: Function;
  values: any;
  loading?: boolean;
  footer?: ReactNode;
  id: string;
  submitText?: string;
  translateSubmitText?: boolean;
  validate?: Function;
  className?: string;
  columnGap?: number;
  styles?: Style[];
  customRenderer?: Function;
  onChange?: (arg0: any) => void;
  forceEnableSubmit?: boolean;
  disableFooter?: boolean;
}

const removeLastFieldBottomMargin = `
> div:last-of-type {
  .input-wrapper:not(.centerLabels):not(.readOnly) {
    margin-bottom: 0;
    > div > span {
      margin-bottom: 0;
    }
  }
}
`;
const StyledForm = styled.form`
  opacity: ${(props: IForm) => (props.loading ? '0.15' : '1')};
  ${transitions('opacity 0.25s ease-in 0s')}
  &.singleColumn:not(.readOnly) {
    > .row:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
  }
  ${removeLastFieldBottomMargin};
  .column {
    ${removeLastFieldBottomMargin};
  }
  ${until('md', `
    &:not(.readOnly) {
      .column {
        margin-bottom: 1.5rem;
      }
    }
  `)};
`;

const FormFooter = styled(Box)`
  display: flex;
  justify-content: flex-end;
  &.centerLabels {
    justify-content: center;
  }
  ${until('md', `
    justify-content: center;
  `)}
`;

const FormContainer = styled.div`
  position: relative;
  .spinner {
    position: absolute;
    left: calc(50% - 0.75rem);
    top: calc(50% - 0.75rem);
  }
`;

export const chunkItems = (fields: any) => {
  let fieldChunks = [] as IFormField[][];
  let twoColumnItemsTemp = [] as IFormField[];
  const isFullWidth = (field: IFormField) => {
    if (!field) return true;
    return field.fullWidth || field.subFields;
  };

  /**
   * We split the field array into items and arrays of items (chunks) based on
   * whether or not the field is fullWidth.
   */
  for (let i = 0; i < fields.length; i += 1) {
    const item = fields[i];
    const nextItem = fields[i + 1];
    if (isFullWidth(item)) {
      fieldChunks.push(item);
    } else if (!isFullWidth(nextItem)) {
      twoColumnItemsTemp.push(item);
    } else if (isFullWidth(nextItem)) {
      twoColumnItemsTemp.push(item);
      fieldChunks = [...fieldChunks, twoColumnItemsTemp];
      twoColumnItemsTemp = [];
    }
  }
  return fieldChunks;
};

const Form: React.FC<IForm> = ({
  fields, onSubmit, values, footer, id, customRenderer, submitText, translateSubmitText, validate,
  className, columnGap, styles, onChange, forceEnableSubmit, loading,
  disableFooter,
}) => {
  if (!fields) return <div>No fields</div>;

  const isSingleColumn = styles?.includes('singleColumn');
  let fieldChunks = fields.slice() as any[];

  if (!isSingleColumn) fieldChunks = chunkItems(fields);

  /**
   * Handles the form submission and calls the onSubmit callback
   */
  const onFormSubmit = (formValues: FormData, form: any, callback?: () => void) => {
    if (!onSubmit) return;

    const initialValues = form.getState().initialValues;
    const difference = diff(initialValues, formValues);
    onSubmit(difference, callback, formValues, form);
  };

  const renderForm = ({
    hasValidationErrors, handleSubmit, dirty, submitting, ...rest
  }: FormRenderProps) => {
    return (
      <StyledForm
        onSubmit={handleSubmit}
        id={`${id}-form`}
        className={`${className} ${styles ? styles.join(' ') : ''}`}
        loading={loading}
      >
        <FormSection
          id={`${id}-section`}
          sections={fieldChunks}
          values={values}
          form={id}
          columnGap={columnGap}
          customRenderer={customRenderer}
          styles={styles}
        />
        {(onSubmit || footer) && !disableFooter && (
          <FormFooter mt="2" className={`form-footer ${styles ? styles.join(' ') : ''}`}>
            {onSubmit && (
              <FormSubmitButton
                submitText={submitText}
                submitting={submitting || loading}  // Disable button if submitting or loading
                id={`form-${id}-submit`}
                disabled={hasValidationErrors || submitting || loading}  // Disable if validation errors
                translate={translateSubmitText === undefined || translateSubmitText === true}
              />
            )}
            {footer}
          </FormFooter>
        )}
        <FormReset {...rest} />
        {onChange && <FormSpy onChange={onChange} />}
      </StyledForm>
    );
  };
  

  return (
    <FormContainer>
      {loading && <Spinner color="mainBlue" />}
      <FinalForm
        initialValues={values}
        onSubmit={onFormSubmit}
        // @ts-ignore
        validate={validate}
        render={(props) => renderForm(props as any)}
      />
    </FormContainer>
  );
};

export { Form };

export const ReadOnlyForm = (props: IForm) => {
  // eslint-disable-next-line react/destructuring-assignment
  let styles = props.styles || [];
  styles = [...styles, ...['readOnly'] as Style[]];
  return (<Form {...props} styles={styles} />);
};
