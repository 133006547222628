import { useEffect } from 'react';

interface FormReset {
  form: any;
  submitSucceeded: boolean;
}
const FormReset: React.FC<FormReset> = ({ form, submitSucceeded }) => {
  useEffect(() => {
    if (submitSucceeded) form.reset();
  }, [submitSucceeded]);

  return null;
};

export { FormReset };
